import React, { useState, useEffect, useRef } from 'react';
import { Form, Field } from 'react-final-form'
import { Link, useNavigate, useParams} from 'react-router-dom';
import { useIndexedDB } from 'react-indexed-db';
import { useApi } from '../components/Api';
import { useCustomFields } from './CustomFields';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, EffectCreative } from 'swiper';
import BottomNavigation from '../components/BottomNavigation'
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import { v4 as uuidv4 } from 'uuid';
import { strings } from '../localization';
import { confirmAlert } from 'react-confirm-alert'; // Import

//const regionNamesInLocaleLanguage = new Intl.DisplayNames('fr', { type: 'region' });
const sleep = ms => new Promise(resolve => setTimeout(resolve, ms))

const emailRegExp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

const validate = values => {

    const errors = {}
   
    if (!values.company) {
      errors.company = 'La raison sociale est obligatoire'
    }
    if (!values.billing_address_1) {
      errors.billing_address_1 = 'L\'adresse est obligatoire'
    }
    if (!values.billing_postal_code) {
      errors.billing_postal_code = 'Le code postal est obligatoire'
    }
    if (!values.billing_city) {
      errors.billing_city = 'La ville est obligatoire'
    }

    if (!values.billing_country) {
        errors.billing_country = 'La ville est obligatoire'
      }
   
   
    if (!values.billing_phone && !values.billing_mobile) {
      errors.billing_phone = 'Le téléphone est obligatoire'
      errors.billing_mobile = 'Le téléphone est obligatoire'
    }
    if (!values.billing_email || ! emailRegExp.test(values.billing_email)) {
      errors.billing_email = 'L\'email est obligatoire'
    }

    

    return errors

}

const validateAddress = values => {

    const errors = {}

    if (!values.address1) {
        errors.address1 = 'L\'adresse est obligatoire'
    }
    if (!values.postal_code) {
        errors.postal_code = 'Le code postal est obligatoire'
    }
    if (!values.city) {
        errors.city = 'La ville est obligatoire'
    }

    if (!values.country) {
        errors.country = 'Le pays est obligatoire'
    }


    return errors
}


const validateContact = values => {

    const errors = {}

    if (!values.first_name) {
        errors.first_name = 'Le prénom est obligatoire'
    }
    if (!values.last_name) {
        errors.last_name = 'Le nom est obligatoire'
    }
    if (!values.phone && !values.mobile) {
        errors.phone = 'Un téléphone est obligatoire'
    }

    if (!values.mobile && !values.phone) {
        errors.mobile = 'Un téléphone est obligatoire'
    }

    if(values.email && !emailRegExp.test(values.email))
    errors.email = "L'email n'est pas valide"

    return errors
}


const CustomerForm = (props) => {

    let { customerRef } = useParams()
    const swiperRef = useRef();
    const [user, { setUser }]  = useApi();
 
    const [ activeIndex, setActiveIndex ] = useState(0);

    const [ showFormAddress, setShowFormAddress ] = useState(false);
    const [ showFormContact, setShowFormContact ] = useState(false);

    const handleCloseFormAddress = () => setShowFormAddress(false);
    const handleCloseFormContact = () => setShowFormContact(false);

    const [ editAddress,setEditAddress] = useState(null);
    const [ editContact,setEditContact] = useState(null);

    const [ customer, setCustomer ] = useState(props.customer);
 

    const [ formChanges, setFormChanges ] = useState(false)
    const [ saved, setSaved ] = useState(false)

     //let customers = JSON.parse( localStorage.getItem('customers') ) 
     const { getAll, update } = useIndexedDB('catalog');
     const [ customers, setCustomers] = useState([])
    
    
     useEffect(() => {
         getAll().then(dataFromDB => {
             let foundCustomers = dataFromDB.find(i => i.type == 'customers')?.data ?? [];             
             let foundCustomer = foundCustomers.find((c) => c.ref == customerRef) 

             setCustomer(foundCustomer) 
             setCustomers(foundCustomers)


            if(foundCustomer)
            setCustomer(foundCustomer)
            else
            setCustomer({ref:'',addresses: [], contacts: [], prospect: true, user: user, billing_duplicate: false})   
            
         });
 
     }, []);
 

    const handleSubmitCustomer = (values) => {

        
        let updated_customer = { ...customer, ...values } 
        
        if(! updated_customer.ref ){
            updated_customer.ref = "NC"+user.ref+Date.now();
            updated_customer.prospect = true;
            
        }
        if(updated_customer.billing_duplicate)
        {
            updated_customer.addresses.push({
                ref: "NA"+user.ref+Date.now(),
                address1: updated_customer.billing_address_1,
                address2: updated_customer.billing_address_2,
                address3: '',
                postal_code: updated_customer.billing_postal_code,
                city: updated_customer.billing_city,
                country: updated_customer.billing_country,
                phone: updated_customer.billing_phone,
                mobile: updated_customer.billing_mobile,
                email: updated_customer.billing_email
            })

            updated_customer.billing_duplicate = false;
        }

        setCustomer(updated_customer)
        setFormChanges(false)
        

        let newCustomers = customers.map(c => 
            c.ref == updated_customer.ref ? updated_customer : c
        )     
        
        if(! newCustomers.find(c => c.ref === updated_customer.ref))
        newCustomers.push(updated_customer);

        update({type: 'customers', data: newCustomers}).then(event => {

            setSaved(true)
            setUser({...user,customer_num: user.customer_num+1})

        })

        //props.handleSubmit(updated_customer);

    }

    const handleSubmitAddress = values => {

        let addresses = customer.addresses

        if(values.ref)
        addresses = addresses.map((a) => {
            
            if(a.ref == values.ref)
            return { ...a, ...values }
            else
            return a
            
        }) 
        else
        {
            values.ref = "NA"+user.ref+Date.now();
            addresses = [ ...addresses, values ]

        }


        let newCustomer = {...customer, addresses: addresses}

        setUser({...user,address_num: user.address_num+1})

        setCustomer(newCustomer)

        setFormChanges(true)

        setShowFormAddress(false)

        

    }

    const handleSubmitContact = values => {

        let contacts = customer.contacts

        if(values.ref)
        contacts = contacts.map((c) => {
            
            if(c.ref == values.ref)
            return { ...c, ...values }
            else
            return c
            
        }) 
        else
        {
            values.ref = "NC"+user.ref+Date.now();
            contacts = [ ...contacts, values ]

        }

        let newCustomer = {...customer, contacts: contacts}

        setUser({...user,contact_num: user.contact_num+1})

        setCustomer(newCustomer)
        setFormChanges(true)

        setShowFormContact(false)

    }

    

    const handleEditAddress = (address) => {

        setEditAddress(address)
        setShowFormAddress(true)

    }


    const handleDeleteAddress = (address) => {

        confirmAlert({
            title: 'Supprimer une addresse',
            message: 'Êtes-vous sûr de vouloir supprimer cette adresse ?',
            buttons: [
              {
                label: 'Oui',
                onClick: () => {
                    setEditAddress(null)
                    setCustomer({...customer,addresses:customer.addresses.filter(a => a != address)})
                    setFormChanges(true)
                }
              },
              {
                label: 'Annuler',
                onClick: () => {
                    
                }
              }
            ]
        });

       

    }

    const handleEditContact = (contact) => {

        setEditContact(contact)
        setShowFormContact(true)

    }

    const handleDeleteContact = (contact) => {

        confirmAlert({
            title: 'Supprimer une addresse',
            message: 'Êtes-vous sûr de vouloir supprimer ce contact ?',
            buttons: [
              {
                label: 'Oui',
                onClick: () => {
                    setEditContact(null)
                    setCustomer({...customer,contacts:customer.contacts.filter(c => c != contact)})
                    setFormChanges(true)
                }
              },
              {
                label: 'Annuler',
                onClick: () => {
                    
                }
              }
            ]
        });

        
    }

    const [ { RenderInput, RenderCheckbox, RenderDate, RenderTextArea, RenderClientField, RenderSelect, RenderCountryField} ] = useCustomFields()

    

    const fieldClass = {label:'col-1', input:'col-2'}
    let submit

   
    const formatContact = (value,name) => ! value || customer?.prospect ? {label:user?.display_name, value:user?.ref} : {label: value?.display_name, value: value?.ref}
    const parseContact = (value, name) => value ? { ref: value.value } : ''



    return (
        <>
            <Form
            initialValues={customer}
            onSubmit={handleSubmitCustomer}
            validate={validate}
            render={({ handleSubmit, form, values, submitting, pristine, dirty, submitSucceeded, modifiedSinceLastSubmit }) => {
                submit = handleSubmit
                
                return (
                <>
                <form onSubmit={handleSubmit} className="customers-form">
                    <div className='container-xxl'>
                        <div className='row d-block'>
                            <Swiper ref={ swiperRef } spaceBetween={100} onActiveIndexChange={ (swiper) => setActiveIndex(swiper.activeIndex)} effect="creative"
                                        creativeEffect={{
                                            prev: {
                                                shadow: true,
                                                translate: [0, 0, -100],
                                                opacity: 0
                                            },
                                            next: {
                                                translate: [0, "100%", 0],
                                                opacity: 0
                                            },
                                        }}
                                        modules={ [ EffectCreative ] } 
                                        allowTouchMove={ false }
                                        simulateTouch={ false }  >
                            <SwiperSlide className='border-1 border-white border p-5 bg-primary'>   
                                <div className="row">
                                    <div className='col-lg-6'>
                                        <div className='row align-items-center'>
                                            <Field name="ref" readonly label={ <div className='d-flex justify-content-between align-items-center'>{ strings.client_edition}<i className={ 'fa fa-circle  ms-3 text-' + (customer?.monitoring === null ? 'warning' : ( customer?.monitoring ? 'success' : 'danger' )) }></i></div> } placeholder="Code" classes={ {label:'col-3',input:'col-3'}} component={ RenderInput } />
                                            <Field name="company" label={false} maxLength={35} placeholder={ strings.client_raison } classes={ {label:'col-3',input:'col-6'}} component={ RenderInput } />
                                            <Field name="billing_address_1" maxLength={35} label={ strings.customer_address} classes={ {label:'col-3', input:'col-9'} } component={ RenderInput } />
                                            <Field name="billing_address_2" maxLength={35} label={ strings.complement } classes={ {label:'col-3', input:'col-9'} } component={ RenderInput } />
                                            <Field name="billing_postal_code" maxLength={35} label={ strings.customer_postal_code} classes={ {label:'col-3', input:'col-3'} } component={ RenderInput } />
                                            <Field name="billing_city" maxLength={35} label={ strings.customer_city } classes={ {label:'col-1 text-end', input:'col-5'} } component={ RenderInput } />
                                            <Field name="billing_country" maxLength={35} label={ strings.customer_country } classes={ {label:'col-3', input:'col-9'} } component={ RenderCountryField }  />
                                            <Field name="billing_phone" maxLength={21} label={ strings.customer_phone} placeholder="Fixe" classes={ {label:'col-3', input:'col-4'} } component={ RenderInput } />
                                            <Field name="billing_mobile" maxLength={21} label={ false } placeholder={ strings.customer_mobile} classes={ {label:'col-1', input:'col-5'} } component={ RenderInput } />                                            
                                            <Field name="billing_email" maxLength={69} label={ strings.email } classes={ {label:'col-3', input:'col-9'} } component={ RenderInput } />
                                            <Field name="billing_duplicate" placeholder={ strings.billing_duplicate } classes={ {label:'col-3', input:'col-9'} } type="checkbox" component={ RenderCheckbox } />
                                        </div>
                                    </div>
                                    <div className='col-lg-6'>
                                        <div className='row justify-content-end align-items-center'>
                                            <Field name="contact" label={ strings.representant } classes={ {label:'col-3',input:'col-9'}} parse={ parseContact } format={ formatContact } component={ RenderSelect }   options={[ customer?.prospect ? {label:user?.display_name, value:user?.ref} : {label: customer?.user?.display_name, value: customer?.user?.ref}]} />      
                                            <Field name="company_type" maxLength={35} label={ strings.forme_jur } classes={ {label:'col-3', input:'col-9'} } component={ RenderInput } />
                                            <Field name="company_siret" maxLength={14} label={ strings.siret } classes={ {label:'col-3', input:'col-9'} } component={ RenderInput } />
                                            <Field name="company_ape" maxLength={7} label={ strings.ape } classes={ {label:'col-3', input:'col-9'} } component={ RenderInput } />
                                            <Field name="vat_number" maxLength={35} label={ strings.intracom } classes={ {label:'col-3', input:'col-9'} } component={ RenderInput } />
                                            <Field name="payment_method" maxLength={35} label={ strings.reglement } classes={ {label:'col-3', input:'col-9'} } component={ RenderInput } />
                                            <Field name="payment_delay" maxLength={35} label={ strings.delai } classes={ {label:'col-3', input:'col-9'} } component={ RenderInput } /> 
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                            <SwiperSlide className='border-1 border-white border p-5 bg-primary'>
                                <div className='row customer-items mb-3'>
                                { customer?.addresses.map( (address,index) => {

                                    return (
                                        <div className='col-lg-4' key={ index }>
                                            <div className='bg-secondary p-3 text-start customer-item h-100'>
                                                <strong>{ address.address1 }</strong><br />
                                                { address.address2 && 
                                                <div>{ address.address2 }</div> }
                                                { address.address3 && 
                                                <div>{ address.address3 }</div> }
                                                { [ address.postal_code,address.city, address.country?.label].filter(a => a).join(' ')  }
                                                { address.phone && 
                                                <div><strong><i className='fa fa-phone-alt me-2'></i></strong>{ address.phone }</div> }
                                                { address.email && 
                                                <div><strong><i className='fa fa-envelope me-2'></i></strong>{ address.email }</div> }
                                                <div className='btn-group'>
                                                    { customer.prospect && <button  type="button" className='btn btn-primary me-1' onClick={ () => handleDeleteAddress(address) }><i className='fa fa-trash'></i></button> }
                                                    <button  type="button" className='btn btn-primary' onClick={ () => handleEditAddress(address) }><i className='fa fa-edit'></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                </div>
                                <button type='button' onClick={ () => handleEditAddress(null) } className='btn btn-secondary btn-lg'>
                                    <i className='fa fa-plus me-3'></i>{ strings.address_add }
                                </button>
                            </SwiperSlide>
                            <SwiperSlide className='border-1 border-white border p-5 bg-primary'>  
                            <div className='row customer-items mb-3'>
                                { customer?.contacts.map( (contact,index) => {

                                    return (
                                        <div className='col-lg-4' key={ index }>
                                            <div className='bg-secondary p-3 text-start customer-item h-100'>
                                                <strong>{ [contact.first_name,contact.last_name].join(' ') }</strong><br />
                                                { contact.position}<br></br>
                                                { contact.phone && 
                                                <div><strong><i className='fa fa-phone-alt me-2'></i></strong> : { contact.phone }</div> }
                                                { contact.email && 
                                                <div><strong><i className='fa fa-envelope me-2'></i></strong> : { contact.email }</div> }
                                                <div className='btn-group'>
                                                    { customer.prospect && <button  type="button" className='btn btn-primary me-1' onClick={ () => handleDeleteContact(contact) }><i className='fa fa-trash'></i></button> }
                                                    <button  type="button" className='btn btn-primary' onClick={ () => handleEditContact(contact) }><i className='fa fa-edit'></i></button>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                                </div>
                                <button type='button' onClick={ () => handleEditContact(null) } className='btn btn-secondary btn-lg'>
                                    <i className='fa fa-plus me-3'></i>{ strings.contact_add }
                                </button>
                            </SwiperSlide>
                            </Swiper>
                        </div>   
                    </div>
                </form>
                <BottomNavigation className="justify-content-around">
                    <button type="button" onClick={ () => {props.updateTitle(strings.customer_details); swiperRef.current?.swiper.slideTo(0)} } className={ (swiperRef.current?.swiper.activeIndex == 0 ? "active " : "" ) + "btn btn-lg col-auto" }><i className='fs-1 fa fa-edit me-3'></i> { strings.informations }</button>
                    <button type="button" disabled={ ! customer?.ref } onClick={ () => {props.updateTitle(strings.addresses_list); swiperRef.current?.swiper.slideTo(1)} } className={ (swiperRef.current?.swiper.activeIndex == 1 ? "active " : "" ) + "btn btn-lg col-auto" }><i className='fs-1 fa fa-map me-3'></i> { strings.addresses}</button>
                    <button type="button" disabled={ ! customer?.ref } onClick={ () => {props.updateTitle(strings.contacts_list); swiperRef.current?.swiper.slideTo(2)} } className={ (swiperRef.current?.swiper.activeIndex == 2 ? "active " : "" ) + "btn btn-lg col-auto" }><i className='fs-1 fa fa-users me-3'></i> { strings.contacts }</button>
                    <button type="button" disabled={ ! customer?.prospect || (! dirty && ! formChanges) } onClick={ event => { submit(event) }} className={ (swiperRef.current?.swiper.activeIndex == 5 ? "active " : "" ) + "btn btn-lg col-auto" + (saved && !dirty && !formChanges ? '' : '') }>{ saved && !dirty && !formChanges ? <><i className='fs-1 fa fa-check me-3'></i> { strings.saved }</> : <><i className='fs-1 fa fa-save me-3'></i> { strings.enregistrer }</> }</button>
                </BottomNavigation>
                </>
            )}}
            />            
            <Offcanvas placement="bottom" className="h-auto" show={ showFormAddress } onHide={handleCloseFormAddress}>
                <Offcanvas.Header closeButton className="border-bottom pb-3 bg-primary text-white"> 
                <Offcanvas.Title className="text-center flex-grow-1">{ editAddress ? strings.address_edit : strings.address_new }</Offcanvas.Title>
                </Offcanvas.Header>        
                <Offcanvas.Body>
                    <Form 
                        initialValues={editAddress}
                        onSubmit={handleSubmitAddress}
                        validate={validateAddress}
                        render={({ handleSubmit, form, submitting, pristine, values, submitSucceeded, modifiedSinceLastSubmit }) => (
                            <form onSubmit={handleSubmit} className="address-form">
                                <div className='container-fluid'>
                            
                                    <div className='row align-items-center justify-content-center'>
                                        <Field name="address1" maxLength={35} label={ strings.customer_address} classes={ {...fieldClass, input:'col-3' } }  component={ RenderInput } />
                                        <Field name="address2" maxLength={35} label={ strings.customer_address_2 } classes={ {...fieldClass, input:'col-3' } } component={ RenderInput } />
                                        <Field name="address3" maxLength={35} label={ strings.customer_address_3 } classes={ {...fieldClass, input:'col-3' } } component={ RenderInput } />
                                        <Field name="postal_code" maxLength={35} label={ strings.customer_postal_code } classes={ fieldClass } component={ RenderInput } />
                                        <Field name="city" maxLength={35} label={ strings.customer_city } classes={ fieldClass } component={ RenderInput } />
                                        <Field name="country" maxLength={35} label={ strings.customer_country } classes={ fieldClass } component={ RenderCountryField }  />
                                        <Field name="phone" maxLength={21} label={ strings.customer_phone } classes={ fieldClass } component={ RenderInput } />
                                        <Field name="mobile" maxLength={21} label={ strings.customer_mobile } classes={ fieldClass } component={ RenderInput } />
                                        <Field name="email" maxLength={69} label={ strings.email} classes={ fieldClass } component={ RenderInput } />
                                    </div>
                                    <div className='row justify-content-center mt-3'>
                                        <button type="submit" className='btn btn-submit btn-secondary col-auto btn-lg'>{ editAddress ? <><i className='fa fa-pencil-alt me-3'></i>{ strings.address_edit }</> : <><i className='fa fa-plus me-3'></i>{ strings.address_add }</> }</button>
                                    </div>
                                </div>
                            </form>
                        )} />
                </Offcanvas.Body>    
            </Offcanvas>
        
            <Offcanvas placement="bottom"  className="h-auto" show={ showFormContact } onHide={handleCloseFormContact}>
                <Offcanvas.Header closeButton className="border-bottom pb-3 bg-primary text-white">
                <Offcanvas.Title className="text-center flex-grow-1">{ editContact ? strings.contact_edit : strings.contact_add }</Offcanvas.Title>
                </Offcanvas.Header>        
                <Offcanvas.Body>
                    <Form 
                        initialValues={editContact}
                        onSubmit={handleSubmitContact}
                        validate={validateContact}
                        render={({ handleSubmit, form, submitting, pristine, values, submitSucceeded, modifiedSinceLastSubmit }) => (
                            <form onSubmit={handleSubmit} className="contact-form">
                                <div className='container-fluid'>
                                    <div className='row align-items-center justify-content-center'>
                                        <Field name="first_name" maxLength={35} label={ strings.first_name } classes={ fieldClass } component={ RenderInput } />
                                        <Field name="last_name" maxLength={35} label={ strings.last_name } classes={ fieldClass } component={ RenderInput } />
                                        <Field name="position" maxLength={35} label={ strings.position } classes={ fieldClass } component={ RenderInput } />
                                    </div>
                                    <div className='row align-items-center justify-content-center'>                         
                                        <Field name="phone" maxLength={21} label={ strings.customer_phone } classes={ fieldClass } component={ RenderInput } />
                                        <Field name="mobile" maxLength={21} label={ strings.customer_mobile } classes={ fieldClass } component={ RenderInput } />
                                        <Field name="email" maxLength={69} label={ strings.email } classes={ fieldClass } component={ RenderInput } />
                                    </div>
                                    <div className='row justify-content-center mt-3'>
                                        <button type="submit" className='btn btn-submit btn-secondary col-auto btn-lg'>{ editContact ? <><i className='fa fa-pencil-alt me-3'></i>{ strings.contact_edit }}</> : <><i className='fa fa-plus me-3'></i>{ strings.contact_new }</> }</button>
                                    </div>
                                </div>
                            </form>
                        )} />
                </Offcanvas.Body>    
            </Offcanvas>
        </>
    )
}

export default CustomerForm;