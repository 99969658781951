import { strings } from "../localization";

function LoginBtn(){

    

    return (
        <button type="submit" className="btn border w-100 border-primary text-primary btn-submit">
            { strings.connexion } <i className="icon-login"></i>
        </button>
    )
}

export default LoginBtn;