import React, { useState, useEffect, useContext } from 'react';
import axios from 'axios';
import config from '../config.json';
import { strings } from '../localization';
import { redirect } from 'react-router';
import UAParser from 'ua-parser-js';


axios.defaults.baseURL = config.baseURL;

function useApi(){
    
    useEffect( () => {

        let foundUser = localStorage.getItem('user');
        if(foundUser)
        setUser(JSON.parse(foundUser))

    }, [])

    
    
    const newUser =  {
        isLoggedIn: false,
        first_name: '',
        last_name: '',
        phone: '',
        email: '',
        order_num: 1000,
        customer_num: 1000,
        contact_num: 1000,
        address_num: 1000,
        token: null,
        ref: '',
        display_name: ''
    }

    const setUser = (user) => {
        localStorage.setItem('user',JSON.stringify(user))
        axios.defaults.headers.common['Authorization'] = `Bearer ${user.token}`;
        _setUser(user)
    }
    const [user, _setUser] = useState();
    const [errors, setErrors] = useState([]);
    
    const setNewUser = () => {
        setUser(newUser)
    }
    const fetchApi = (path, params = {}, data= {}, method = "post") => {

        let headers = { 'Content-Type': 'application/json' }
        params.version = process.env.REACT_APP_VERSION
        let parser = new UAParser(window.navigator.userAgent);
        params.os = parser.getOS();
        params.browser = parser.getBrowser(); 
       

        return axios({
            method: method,
            url: path,
            params: params,
            data: data,
            headers: headers
        })        
        .catch(handleApiError)
        .then(response => response)

    }
    

    const fetchImage = (path, params = {}, callBack = (response) => {}) => {

        
        axios.post(path, params)
        .then(callBack)
        .catch(handleApiError);   

    }


    const handleApiError = (error) => {

        console.log(error)

        if(error.hasOwnProperty('response') && error.response.hasOwnProperty('data') && error.response.data.message)
        setErrors([error.response.data.message]);
        else
        setErrors([ strings.api_fail ]);

        switch (error.response?.status) {
            case 401:      
            handleLogout();            
            break;

        }


    }



    const handleLogin = (username, password) => {  
        
        

        let params = { username: username, password : password, version : process.env.REACT_APP_VERSION };  
        let parser = new UAParser(window.navigator.userAgent);
        params.os = parser.getOS();
        params.browser = parser.getBrowser(); 
        let headers = { 'Content-Type': 'application/json' }

        // GET TOKEN
        axios.post('/login_check', params, { headers: headers })
        .then(response => {

            let token = response.data.token
            axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
            
            // GET USER INFO
            fetchApi('/user').then( response => {

                let loggedInUser = { ...newUser, ...response.data, isLoggedIn: true, token:  token}

                // isLoggedIn : true,
                // firstName : response.data.firstName,
                // lastName : response.data.lastName,
                // phone : response.data.phone,
                // email : response.data.email

                setUser(loggedInUser); 

                
                
              
            })

            
        })
        .catch( handleApiError );

    }

  


    const handleLogout = () => {    
        
        setUser(newUser)
        localStorage.removeItem('user')
        setErrors([])
        
    }

    return [        
        user,
        {
        'setUser': setUser,
        'setNewUser': setNewUser,
        'setErrors': setErrors,
        'logout': handleLogout,
        'login': handleLogin,
        'fetchAPI': fetchApi,
        'fetchImage': fetchImage,
        'errors' : errors,
        }  
    ]

}

export { useApi };
