import { strings } from "../localization";

function LogoutBtn(props){
  
    return (
        <button className="btn border-white text-white ms-4 fs-5" onClick={ props.onLogout }>
            { strings.deconnecter } <i className="icon-login ms-2"></i>
        </button>
    )
}

export default LogoutBtn;