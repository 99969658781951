import React, { useState } from 'react';
import { Link, useNavigate } from "react-router-dom";

import {UserContext} from '../contexts/UserContext';
import LogoutBtn from './LogoutBtn';
import LoginBtn from './LoginBtn';
import { strings } from '../localization';

function Auth(props){

  
    const [state,setState] = useState({username:'',password:''});
    const [errors, setErrors] = useState([]);
    let navigate = useNavigate();

    const handleLogout = (event) => {
        
        event.preventDefault();
        setErrors([]);
        props.onLogout();
        
    }

     const handleSubmit = (event) => {

        event.preventDefault();
       
        const loggedIn = props.onLogin(state.username,state.password);  
        
        
       

    }

    const handleInputChange = (event) => {

        const target = event.target;
        const value = target.type === 'checkbox' ? target.checked : target.value;
        const name = target.name;

        setState(prevState => ({ ...prevState, [name]: value }));
    
    }

    
    
    return(           
        <UserContext.Consumer>
            { ( {user, setUser} ) => {
                
                return (
                    <div className="App-login">
                        <h1>{ strings.welcome}</h1>
                        <h2>{ strings.baseline }</h2>                        
                        <div className="row justify-content-center">
                            <div className="col-lg-6 col-xxl-3">
                                { ! user?.isLoggedIn 
                                ? 
                                    <div>
                                        <hr />
                                        { props.errors.length > 0  &&
                                            <div className="alert alert-danger">{ props.errors.join('<br>') }</div>
                                        }
                                        <form onSubmit={ handleSubmit }>
                                            <div className="form-group mb-3">
                                                <input type="text" className="form-control" name="username" onChange={ handleInputChange } placeholder={ strings.login_username } />
                                            </div>
                                            <div className="form-group mb-3">
                                                <input type="password" className="form-control" name="password" onChange={ handleInputChange } placeholder={ strings.login_password } />    
                                            </div> 
                                            <div className="form-group">
                                               <LoginBtn />
                                            </div>
                                        </form>
                                    </div>
                                :
                                    <div>
                                        
                                        <Link to="/dashboard" className="btn btn-success mt-5">
                                            <i className="fa fa-home text-white me-2"></i>{ strings.goto_home }
                                        </Link>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                )
            }}
        </UserContext.Consumer>
    )
  
}

export default Auth;