import LocalizedStrings from 'react-localization';



let strings = new LocalizedStrings({
    fr:{
        bonjour : 'Bonjour',
		clients : 'Clients',
		articles : 'Articles',
		commandes : 'Commandes',
		synchro : 'Synchro',
		catalogue : 'Catalogue',  
        ref : 'REF',      
		raison : 'RAISON',
        client_raison : 'Raison',
		tel : 'TEL',
		contact : 'CONTACT',
		ville : 'VILLE',
		pays : 'PAYS',
		rechercher : 'Rechercher',
		ajouter_client : 'Ajouter un client',
		retour : 'RETOUR',
		liste_clients : 'Liste des clients',
		rechercher_client : 'Rechercher un client...',
		client_edition : 'Client',
		representant : 'Représentant',
		forme_jur : 'Forme juridique',
		complement : 'Complément',
		siret : 'SIRET',
		ape : 'APE',
		cp : 'CODE POSTAL',
		email : 'Email',
		intracom : 'Numéro intracom.',
		reglement : 'Mode de réglement',
		delai : 'Délai de réglement',
		min_commande : 'Minimum {min} articles',
		liste_articles : 'Liste des articles',
		designation : 'DESIGNATION',
		prix : 'PRIX',
		stock : 'STOCK',
		qte_min : 'QTE MIN',
		actions : 'ACTIONS',
		liste_commandes : 'Liste des commandes',
		order_table_date : 'DATE',
		order_table_date_livraison : 'DATE LIVRAISON',
        order_table_type : 'TYPE',
		order_table_client : 'CLIENT',
		order_table_lignes : 'LIGNES',
		order_table_montant : 'MONTANT',
		choisissez_type : 'Choisissez un type',
		choisissez_etat : 'Choisissez un état',
		commandes_page : 'Commandes par page',
		nouvelle_commande : 'Nouvelle commande',
		update_check : 'Recherche de mise à jour',
        synchro_produits : 'Synchroniser le catalogue produits',
		synchro_images : 'Synchroniser les images',
		synchro_commandes : 'Synchroniser les commandes/clients',
		appli_ajour : 'Application à jour',
		appli_maj : 'Mise à jour disponible',
		deconnecter : 'SE DECONNECTER',
		connexion : 'CONNEXION',
		catalogue_produit : 'CATALOGUE PRODUITS',
		afficher : 'Afficher',
		elements : 'éléments',
		quantite : 'Quantité',
		prix_fiche : 'Prix',
		total : 'Total',
		ajouter_panier : 'Ajouter au panier',
		fiche_article : 'Fiche article',
        fiche_technique : 'Fiche technique',
		utilisation : 'Utilisation',
		matiere : 'Matière',
		longueur : 'Longueur',
		largeur : 'Largeur / Diamètre',
		hauteur : 'Hauteur',
		poids : 'Poids',
		contenance : 'Contenance',
		num_commande : 'N° commande',
		lignes_commande : 'Lignes',
		type_commande : 'Type',
		client_commande : 'Client',
		date_commande : 'Date commande',
		date_livraison : 'Date livraison',
		commentaire : 'Commentaire',
		panier_vide : 'Le panier est vide',
		informations : 'Informations',
		details : 'Détails',
		signature : 'Signature',
		enregistrer : 'Enregistrer',
		validation : 'Validation',
		rechercher_articles : 'Rechercher des articles',
		signature_client : 'Signature du client',
		signature_aucune : 'Aucune signature pour ce document',
        api_error : 'Erreur : le serveur ne répond pas',
        login_username: 'Identifiant',
        login_password: 'Mot de passe',
        welcome : 'Bienvenue',
        baseline : 'Application pour les commerciaux',
        goto_home : 'Aller à l\'accueil',
        details_commande : 'Détails de la commande',
        articles_page : 'Articles par page',
        articles_of : 'sur',
        articles_all : 'Tous',
        articles_noresult : 'Aucun article trouvé',
        cart_table_title : 'Titre',
        cart_table_qty : 'Quantité',
        cart_table_price : 'Prix',
        cart_table_total : 'Total',
        order_item_table_title : 'Titre',
        order_item_table_qty : 'Quantité',
        order_item_table_price : 'Prix',
        order_item_table_lines : 'Lignes',
        customer_noresult : 'Aucun client trouvé',
        customer_add : 'Création d\'un nouveau client',
        customer_edit : 'Edition d\'un client',
        orders_page : 'Commandes par page',
        orders_of : 'sur',
        orders_all : 'Toutes',
        date_from : 'Du',
        date_to : 'Au',
        orders_noresult : 'Aucune commande trouvée',
        order_add : 'Nouvelle commande',
        order_edit : 'Edition d\'une commande',
        delete: 'Supprimer',
        category_download : 'Téléchargement des images catégorie',
        category_download_completed : 'Mise à jour des images catégorie : terminé',
        category_delete : 'image catégorie supprimée',
        category_delete_plural : 'images catégorie supprimées',
        image_delete : 'image supprimée',
        image_delete_plural : 'images supprimées',
        product_download : 'Téléchargement des images produit',
        product_download_completed : 'Mise à jour des images produit : terminé',
        product_delete : 'image produit supprimée',
        product_delete_plural : 'images produit supprimées',
        category_update : 'Mise à jour de la base de donnée catégories',
        product_update : 'Mise à jour de la base de donnée produit',
        customer_update : 'Mise à jour de la base de donnée clients',
        order_update : 'Mise à jour de la base de donnée commande',
        customer_added : 'Synchronisation des prospects',
        order_added : 'Synchronisation des commandes',
        completed : 'terminé',
        close : 'Fermer',
        synchro_title : 'Synchronisation des données',
        customer_details : 'Informations sur le client',
        customer_address : 'Adresse',
        customer_postal_code : 'Code postal',
        customer_country : 'Pays',
        customer_phone : 'Téléphone',
        customer_mobile : 'Portable',
        customer_city : 'Ville',
        addresses : 'Adresses',
        contacts : 'Contacts',
        saved : 'Enregistré',
        addresses_list : 'Liste des adresses',
        address_add : 'Ajouter une adresse',
        address_edit : 'Modifier une adresse',
        address_new : 'Ajouter une nouvelle adresse',
        contacts_list : 'Liste des contacts',
        contact_add : 'Ajouter un contact',
        contact_edit : 'Modifier un contact',
        contact_new : 'Ajouter un nouveau contact',
        first_name : 'Prénom',
        last_name : 'Nom',
        position : 'Fonction',
        order_type_0 : 'Devis',
        order_type_1 : 'Bon de commande',
        order_type_2 : 'Préparation de livraison',
        order_type_3 : 'Bon de livraison',
        order_type_4 : 'Bon de retour',
        order_type_5 : 'Bon d\'avoir',
        order_type_6 : 'Facture',
        order_type_7 : 'Facture comptabilisée',
        order_status_0 : 'Créé',
        order_status_1 : 'Validé',
        order_status_2 : 'Envoyé',
        order_status_3 : 'Synchronisé',
        order_details : 'Informations sur la commande',
        confirmed : 'Validé',
        confirmation: 'Validation',
        addresse_commande : 'Adresse de livraison',
        choisissez_adresse : 'Choisissez une adresse de livraison',
        failed : 'Erreurs de chargement',
        billing_duplicate : 'Dupliquer l\'adresse de facturation',
        destock: 'Destockage',

        
        
		
    },
    es:{
        bonjour : 'Hola',
		clients : 'Clientes',
		articles : 'Articulos',
		commandes : 'Pedidos',
		synchro : 'Sincro',
		catalogue : 'Catalogo',
        ref : 'REF',
		raison : 'RAZON',
        client_raison : 'Razon',
		tel : 'TEL',
		contact : 'CONTACTO',
		ville : 'CIUDAD',
		pays : 'PAIS',
		rechercher : 'Buscar',
		ajouter_client : 'Anadir un cliente',
		retour : 'VOLVER',
		liste_clients : 'Lista des clientes',
		rechercher_client : 'Buscar un cliente...',
		client_edition : 'Cliente',
		representant : 'Representante',
		forme_jur : 'Forma juridica',
		complement : 'Complemento',
		siret : 'CIF',
		ape : '-',
		cp : 'CODIGO POSTAL',
		email : 'Email',
		intracom : 'Numero de intracom.',
		reglement : 'Metodo de pago',
		delai : 'Retraso de pago',
		min_commande : 'Minimo {min} articulos',
		liste_articles : 'Lista de articulos',
		designation : 'DESIGNACION',
		prix : 'PRECIO',
		stock : 'STOCK',
		qte_min : 'QDAD MIN',
		actions : 'ACCIONES',
		liste_commandes : 'Lista de pedidos',
		order_table_date : 'FECHA',
		order_table_date_livraison : 'FECHA DE ENTREGA',
        order_table_type : 'TIPO',
		order_table_client : 'CLIENTE',
		order_table_lignes : 'LINEAS',
		order_table_montant : 'PRECIO',
		choisissez_type : 'Elige un tipo',
		choisissez_etat : 'Elige un estado',
		commandes_page : 'Pedidos por pagina',
		nouvelle_commande : 'Nuevo pedido',
		update_check : 'Recherche de mise à jour',
        synchro_produits : 'Sincronizar el catálogo de productos',
		synchro_images : 'Sincronizar imágenes',
		synchro_commandes : 'Sincronizar pedidos/clientes',
		appli_ajour : 'Applicacion actualizada',
		appli_maj : 'Actualización disponible',
		deconnecter : 'DESCONECTAR',
		connexion : 'CONEXION',
		catalogue_produit : 'CATALOGO DE PRODUCTOS',
		afficher : 'Mostrar',
		elements : 'elementos',
		quantite : 'Quantidad',
		prix_fiche : 'Precio',
		total : 'Total',
		ajouter_panier : 'Anadir al carrito',
        fiche_article : 'Fiche article',
		fiche_technique : 'Ficha tecnica',
		utilisation : 'Utilisacion',
		matiere : 'Material',
		longueur : 'Longitud',
		largeur : 'Ancho / Diametro',
		hauteur : 'Altura',
		poids : 'Peso',
		contenance : 'Capacidad',
		num_commande : 'N° de pedido',
		lignes_commande : 'Lineas',
		type_commande : 'Tipo',
		client_commande : 'Cliente',
		date_commande : 'Fecha del pedido',
		date_livraison : 'Fecha de entrega',
		commentaire : 'Commentario',
		panier_vide : 'LEl carrito esta vacio',
		informations : 'Informationes',
		details : 'Detalles',
		signature : 'Firma',
		enregistrer : 'Guardar',
		validation : 'Validacion',
		rechercher_articles : 'Buscar articulos',
		signature_client : 'Firma del cliente',
        signature_aucune : 'No hay firma para este documento',
        api_error : 'Error : el servidor web no responde',
        login_username: 'Login',
        login_password: 'Password',
        welcome : 'Bienvenido',
        baseline : 'Aplicación para vendedores',
        goto_home : 'Inicio',
        details_commande : 'Detalles del pedido',
        articles_page : 'Artículos por página',
        articles_of : 'sobre',
        articles_all : 'Todos',
        articles_noresult : 'Ningun resultado',
        cart_table_title : 'Titulo',
        cart_table_qty : 'Cantidad',
        cart_table_price : 'Precio',
        cart_table_total : 'Total',
        order_item_table_title : 'Titulo',
        order_item_table_qty : 'Cantidad',
        order_item_table_price : 'Precio',
        order_item_table_lines : 'Lineas',
        customer_noresult : 'Ningún cliente',
        customer_add : 'Crear un nuevo cliente',
        customer_edit : 'Editar cliente',
        orders_page : 'Pedidos por página',
        orders_of : 'sobre',
        orders_all : 'Todos',
        date_from : 'Desde',
        date_to : 'Al',
        orders_noresult : 'No pedido',
        order_add : 'Nuevo pedido',
        order_edit : 'Editar pedido',
        delete : 'Eliminar',
        category_download : 'Descarga de imagen de categoría',
        category_download_completed : 'Actualización de imagen de categoría: completada',
        category_delete : 'imagen de categoría eliminada',
        category_delete_plural : 'imágenes de categoría eliminadas',
        image_delete : 'imagen eliminada',
        image_delete_plural : 'imágenes eliminadas',
        product_download : 'Descarga de la imagen del producto',
        product_download_completed : 'Actualización de imágenes de productos: completado',
        product_delete : 'imagen del producto eliminada',
        product_delete_plural : 'imágenes de productos eliminados',
        category_update : 'Actualización de la base de datos de categorías',
        product_update : 'Actualización de la base de datos del producto',
        customer_update : 'Actualización de base de datos de clientes',
        order_update : 'Actualizar base de datos de pedidos',
        customer_added : 'Sincronización de clientes potenciales',
        order_added : 'Sincronización de pedidos',
        completed : 'completado',
        close : 'Cerrar',
        synchro_title : 'Sincronización de datos',
        customer_details : 'Detalles del cliente',
        customer_address : 'Dirección',
        customer_address_2 : 'Dirección (+)',
        customer_address_3 : 'Complemento',
        customer_postal_code : 'Código postal',
        customer_country : 'País',
        customer_phone : 'Teléfono',
        customer_mobile : 'Móvil',
        customer_city : 'Ciudad',
        addresses : 'Direcciones',
        contacts : 'Contactos',
        saved : 'Guardado',
        addresses_list : 'Lista de direcciones',
        address_add : 'Anadir una direccion',
        address_edit : 'Editar una dirección',
        address_new : 'Anadir una direccion',
        contacts_list : 'Lista de contactos',
        contact_add : 'Anadir un contacto',
        contact_edit : 'Editar contacto',
        contact_new : 'Anadir un contacto',
        first_name : 'Nombre',
        last_name : 'Apellido',
        position : 'Función',
        order_type_0 : 'Presupuesto',
        order_type_1 : 'Orden de compra',
        order_type_2 : 'Preparación para la entrega',
        order_type_3 : 'Albarán de entrega',
        order_type_4 : 'Comprobante de devolución',
        order_type_5 : 'Nota de crédito',
        order_type_6 : 'Factura',
        order_type_7 : 'Factura publicada',
        order_status_0 : 'Creado',
        order_status_1 : 'Validado',
        order_status_2 : 'Enviado',
        order_status_3 : 'Sincronizado',
        order_details : 'Información del pedido',
        confirmed : 'Validado',
        confirmation: 'Validación',
        addresse_commande : 'Adresse de livraison',
        choisissez_adresse : 'Choisissez une adresse de livraison',
        failed : 'Erreurs de chargement',
        billing_duplicate : 'Dupliquer l\'adresse de facturation',
        destock: 'Destockage',


       
		
		
    },
    it: {
        bonjour : 'Buongiorno',
		clients : 'Clienti',
		articles : 'Articoli',
		commandes : 'Ordini',
		synchro : 'Sincro',
		catalogue : 'Catalogo',
        ref : 'REF',
		raison : 'RAISON',
        client_raison : 'Raison',
		tel : 'TEL',
		contact : 'CONTATTO',
		ville : 'CITTA',
		pays : 'PAESE',
		rechercher : 'Cerca',
		ajouter_client : 'Aggiungi un cliente',
		retour : 'RITORNO',
		liste_clients : 'Elenco dei clienti',
		rechercher_client : 'Cerca un cliente...',
		client_edition : 'Cliente',
		representant : 'Rappresentante',
		forme_jur : 'Forma legale',
		complement : 'Complémento',
		siret : 'REA',
		ape : '-',
		cp : 'CODICE POSTALE',
		email : 'Email',
		intracom : 'Numero intracom.',
		reglement : 'Metodo di pagamento',
		delai : 'Ritardo nel pagamento',
		min_commande : 'Minimo {min} articoli',
		liste_articles : 'Elenco di articoli',
		designation : 'DESIGNAZIONE',
		prix : 'PREZZO',
		stock : 'STOCK',
		qte_min : 'QTA MIN',
		actions : 'AZIONI',
		liste_commandes : 'Elenco di ordine',
		order_table_date : 'DATA',
		order_table_date_livraison : 'DATA DI CONSEGNA',
        order_table_type : 'TIPO',
		order_table_client : 'CLIENTE',
		order_table_lignes : 'RIGHE',
		order_table_montant : 'IMPORTO',
		choisissez_type : 'Scegli un tipo',
		choisissez_etat : 'Scegli uno stato',
		commandes_page : 'Ordini per pagina',
		nouvelle_commande : 'Nuovo ordine',
		update_check : 'Recherche de mise à jour',
        synchro_produits : 'Sincronizza il catalogo prodotti',
		synchro_images : 'Sincronizza immagini',
		synchro_commandes : 'Sincronizza ordini/clienti',
		appli_ajour : 'Applicazione aggiornata',
		appli_maj : 'Aggiornamento disponibile',
		deconnecter : 'DISCONNETTI',
		connexion : 'CONNESSIONE',
		catalogue_produit : 'CATALOGO PRODOTTI',
		afficher : 'Visualizza',
		elements : 'elémenti',
		quantite : 'Quantita',
		prix_fiche : 'Prezzo',
		total : 'Total',
		ajouter_panier : 'Aggiungi al carrello',
        fiche_article : 'Fiche article',
		fiche_technique : 'Scheda tecnica',
		utilisation : 'Usa',
		matiere : 'Materialee',
		longueur : 'Lunghezza',
		largeur : 'Larghezza / Diametro',
		hauteur : 'Altezza',
		poids : 'Peso',
		contenance : 'Capacità',
		num_commande : 'N° ordine',
		lignes_commande : 'Righe',
		type_commande : 'Tipo',
		client_commande : 'Cliente',
		date_commande : 'Data ordine',
		date_livraison : 'Data di consegna',
		commentaire : 'Commenta',
		panier_vide : 'Il carrello è vuoto',
		informations : 'Informazioni',
		details : 'Dettagli',
		signature : 'Firma',
		enregistrer : 'Salva',
		validation : 'Convalida',
		rechercher_articles : 'Cerca articoli',
		signature_client : 'Firma del cliente',
        signature_aucune : 'Nessuna firma per questo documento',
        api_error : 'Errore: il server non risponde',
        login_username: 'Login',
        login_password: 'Password',
        welcome : 'Welcome',
        baseline : 'Applicazione per venditori',
        goto_home : 'Home page',
        details_commande : 'Dettagli ordine',
        articles_page : 'Articoli per pagina',
        articles_of : 'su',
        articles_all : 'Tutti',
        articles_noresult : 'Nessun articolo',
        cart_table_title : 'Titolo',
        cart_table_qty : 'Quantità',
        cart_table_price : 'Prezzo',
        cart_table_total : 'Totale',
        order_item_table_title : 'Titolo',
        order_item_table_qty : 'Quantità',
        order_item_table_price : 'Prezzo',
        order_item_table_lines : 'Righe',
        customer_noresult : 'Nessun cliente',
        customer_add : 'Creazione di un nuovo cliente',
        customer_edit : 'Modifica cliente',
        orders_page : 'Ordini per pagina',
        orders_of : 'su',
        orders_all : 'Tutti',
        date_from : 'Da',
        date_to : 'Come',
        orders_noresult : 'Nessun ordine',
        order_add : 'Nuovo ordine',
        order_edit : 'Modifica ordine',
        delete: 'Elimina',
        category_download : 'Download immagine categoria',
        category_download_completed : 'Aggiornamento immagine categoria: completato',
        category_delete : 'immagine di categoria eliminata',
        category_delete_plural : 'immagini di categoria eliminate',
        image_delete : 'immagine eliminata',
        image_delete_plural : 'immagini eliminate',
        product_download : 'Download dell\'immagine del prodotto',
        product_download_completed : 'Aggiornamento delle immagini del prodotto: completato',
        product_delete : 'immagine del prodotto eliminata',
        product_delete_plural : 'immagini prodotto eliminate',
        category_update : 'Aggiornamento del database delle categorie',
        product_update : 'Aggiornamento del database del prodotto',
        customer_update : 'Aggiornamento database clienti',
        order_update : 'Aggiorna il database degli ordini',
        customer_added : 'Sincronizzazione lead',
        order_added : 'Sincronizzazione dell\'ordine',
        completed : 'completato',
        close : 'Chiudi',
        synchro_title : 'Sincronizzazione dei dati',
        customer_details : 'Dettagli cliente',
        customer_address : 'Indirizzo',
        customer_address_2 : 'Indirizzo(+)',
        customer_address_3 : 'Completa',
        customer_postal_code : 'Codice postale',
        customer_country : 'Paese',
        customer_phone : 'Télefono',
        customer_mobile : 'Cellulare',
        customer_city : 'Città',
        addresses : 'Indirizzi',
        contacts : 'Contatti',
        saved : 'Salvato',
        addresses_list : 'Elenco indirizzi',
        address_add : 'Aggiungi un indirizzo',
        address_edit : 'Modifica un indirizzo',
        address_new : 'Modifica un indirizzo',
        contacts_list : 'Elenco contatti',
        contact_add : 'Aggiungi contatto',
        contact_edit : 'Modifica contatto',
        contact_new : 'Aggiungi contatto',
        first_name : 'Nome',
        last_name : 'Cognome',
        position : 'Funzione',
        order_type_0 : 'Preventivo',
        order_type_1 : 'Modulo d\'ordine',
        order_type_2 : 'Preparazione consegna',
        order_type_3 : 'Bolla di consegna',
        order_type_4 : 'Bolla di reso',
        order_type_5 : 'Buono da avere',
        order_type_6 : 'Fattura',
        order_type_7 : 'Fattura registrata',
        order_status_0 : 'Creato',
        order_status_1 : 'Convalidato',
        order_status_2 : 'Spedito',
        order_status_3 : 'Sincronizzato',
        order_details : 'Informazioni sull\'ordine',
        confirmed : 'Convalidato',
        confirmation: 'Convalida',
        addresse_commande : 'Adresse de livraison',
        choisissez_adresse : 'Choisissez une adresse de livraison',
        failed : 'Erreurs de chargement',
        billing_duplicate : 'Dupliquer l\'adresse de facturation',
        destock: 'Destockage',

        
        
    },

	
});

export { strings }