import React, { useState, useEffect, forwardRef } from 'react';
import {  useNavigate } from 'react-router-dom';
import OrderForm, {useOrder} from '../forms/OrderForm';
import Ariane from './Ariane';
import { formatCurrency } from './Cart';
import DataTable from 'react-data-table-component';
import bs5Styles from '../assets/themes/bs5';
import DatePicker, { registerLocale } from "react-datepicker";
import { format, isValid } from "date-fns";
import fr from 'date-fns/locale/fr';
import "react-datepicker/dist/react-datepicker.css";
import moment from 'moment';
import Select from "react-select";
import { selectStyles } from '../forms/CustomFields';
import { useIndexedDB } from 'react-indexed-db';
import { strings } from '../localization';
import Customers from './Customers';
import { confirmAlert } from 'react-confirm-alert'; // Import

registerLocale('fr', fr);


function Orders(){

    //let orders =  JSON.parse(localStorage.getItem('orders'))

    const { getAll, update } = useIndexedDB('catalog');
    const [orders, setOrders] = useState([]);
    const [ customers, setCustomers] = useState([]);
   
    useEffect(() => {
        getAll().then(dataFromDB => {
            setOrders(dataFromDB.find(i => i.type === 'orders')?.data ?? [])
            setCustomers(dataFromDB.find(i => i.type === 'customers')?.data ?? [])
        });

        setOrderType(JSON.parse(sessionStorage.getItem('orders.search_type')) ?? []);
        setOrderState(JSON.parse(sessionStorage.getItem('orders.search_status')) ?? []);
        setDateFrom(sessionStorage.getItem('orders.search_from') ?? '');
        setDateTo(sessionStorage.getItem('orders.search_to') ?? '');

       

    },[]);
    
    let navigate = useNavigate();
    const addNewOrder = () => {  

        navigate('/orders/new');
    }
    const editOrder = (ref) => {
        navigate('/order/'+ref)
    }

    const locale = 'fr-FR';

    const { orderTypes, orderStatus } = useOrder()

 


    const columns = [
        {
            name: strings.ref,
            selector: row => row.ref,
            sortable: true,
        },
        {
            name: strings.order_table_date,
            selector: row => row.date,
            sortable: true,
            center: true,
            format: (row,index) => Intl.DateTimeFormat(locale).format(new Date(row.date * 1000)),
        },
        {
            name: strings.order_table_date_livraison,
            selector: row => row.shipping_date,
            sortable: true,
            center: true,
            format: (row,index) => Intl.DateTimeFormat(locale).format(new Date(row.shipping_date * 1000)),
        },
        {
            name: strings.order_table_type,
            selector: row => orderTypes.find( t => t.value == row.type)?.label,
            center: true,
            sortable: true,
        },
        {
            name: strings.order_table_client,
            selector: row => row.customer,
            format: row => customers.find(c => c.ref === row.customer)?.ref ?? '',
            center: true,
            sortable: true,
        },
        {
            name: strings.raison,
            selector: row => row.customer,
            format: row => customers.find(c => c.ref === row.customer)?.company ?? '',
            sortable: true,
        },
        {
            name: strings.order_table_lignes,
            selector: row => row.items.length,
            right: true,
            sortable: true,
        },
        {
            name: 'MONTANT',
            selector: row => formatCurrency(row.total),
            right: true,
            sortable: true,
        },
        {
            name:'ACTIONS',
            selector: row => row.ref,
            right: true,
            width:'100px',
            format: row => row.status >1  ? '' : <button type='button' onClick={ () => handleRemoveOrder(row.ref) } className="btn"><i className='fa fa-trash'></i></button>
        }
    ];

    const handleRemoveOrder = (ref) => {

        confirmAlert({
            title: 'Supprimer une commande',
            message: 'Êtes-vous sûr de vouloir supprimer cette commande ?',
            buttons: [
              {
                label: 'Oui',
                onClick: () => {
                    let newOrders = orders.filter(o => o.ref != ref)
            
                    update({type: 'orders', data: newOrders}).then(event => {               
                                    
                        setOrders(newOrders)  
                    })   
                }
              },
              {
                label: 'Annuler',
                onClick: () => {
                    
                }
              }
            ]
        });

       
         

    }

    const conditionalRowStyles = [
        {
            when: row => ! row.status ,
            style: {
               
            },
        },
        // You can also pass a callback to style for additional customization
        {
            when: row => row.calories < 400,
            style: row => ({ backgroundColor: row.isSpecial ? 'pink' : 'inerit' }),
        },
    ];
    
    const paginationComponentOptions = {
        rowsPerPageText: strings.orders_page,
        rangeSeparatorText: strings.orders_of,
        selectAllRowsItem: true,
        selectAllRowsItemText: strings.orders_all,        
        
    };
  
 

    const handleChangeRaw = (onChange, date) => {

        onChange(date.currentTarget.value)
        const newRaw = new Date(date.currentTarget.value);

        if (newRaw instanceof Date && !isNaN(newRaw)) {            
            onChange(format(new Date(newRaw), "dd-MM-yyyy"));
        }
        else
        {
            console.log('not a date')
        }        
    };

    const DatePickerInput = forwardRef(({ value, onClick }, ref) => (

        <div className="input-group date" >
            <input type="text" className="form-control" onFocus={ onClick} onChange={ (e)=> handleChangeRaw(setDateFrom,e) } value={ value } ref={ref} />
            <span className="input-group-append">
                <span className="input-group-text text-secondary d-block" onClick={onClick} >
                <i className="fa fa-calendar"></i>
                </span>
            </span>
        </div>

    ))

    const [ orderType, setOrderType ] = useState([]);

    
    const handleChangeOrderType = (value) => {
       
        sessionStorage.setItem('orders.search_type', JSON.stringify(value));
        setOrderType(value)
    }

    const [ orderState, setOrderState ] = useState([]);

    
    const handleChangeOrderStatus = (value) => {
        sessionStorage.setItem('orders.search_status', JSON.stringify(value));
        setOrderState(value)
    }

    const [ dateFrom, setDateFrom ] = useState(null)
    const [ dateTo, setDateTo ] = useState(null)

    const filteredOrders = orders.filter( (order) => {
        
        return ( ! dateFrom || order.date >= moment(dateFrom,'D-M-Y').unix() )
        && 
        ( ! dateTo || order.date <= moment(dateTo,'D-M-Y').unix() )
        &&
        ( ! orderState.length || orderState.find(s => parseInt(s.value) === order.status ) )
        &&
        ( ! orderType.length || orderType.find(t => parseInt(t.value) === order.type ) )
        
    })

   
    return (
        
        <div className="orders">
            <Ariane actions={<button type="button" className='me-3 btn btn-secondary' onClick={ addNewOrder }><i className="fa fa-plus"></i> { strings.nouvelle_commande }</button>}>
                <h1 className='titre text-center flex-grow-1'>{ strings.liste_commandes }</h1>
            </Ariane>
            
            <div className='orders-list container-xxl'>
                <div className='search-bar mt-5 mb-3'>
                    <div className="row justify-content-end">
                        <div className='col-lg-2'>
                            <DatePicker  customInput={ <DatePickerInput /> }  locale="fr" selectsStart startDate={ dateFrom ? moment(dateFrom,'D-M-Y').toDate() : new Date() } endDate={ dateTo ? moment(dateTo,'D-M-Y').toDate() : new Date() } placeholderText={ strings.date_from } dateFormat="dd-MM-yyyy" name="date_from" onChange={(date) => { 
                              
                             
                            if (isValid(date)) {
                                
                                setDateFrom(format(new Date(date), "dd-MM-yyyy"));
                            } else {
                                setDateFrom(null);
                            }

                            }} value={ dateFrom } />               
                        </div>
                        <div className='col-lg-2'>
                            <DatePicker customInput={ <DatePickerInput /> }  locale="fr" selectsEnd startDate={ dateFrom ? moment(dateFrom,'D-M-Y').toDate() : new Date() } endDate={ dateTo ? moment(dateTo,'D-M-Y').toDate() : new Date() } minDate={ moment(dateFrom,'D-M-Y').toDate() } placeholderText={ strings.date_to } dateFormat="dd-MM-yyyy" name="date_to" onChange={(date) => { 
                               
                            if (isValid(date)) {
                                setDateTo(format(new Date(date), "dd-MM-yyyy"));
                            } else {
                                setDateTo(null);
                            }

                            }} value={ dateTo } />               
                        </div>                       
                        <div className='col-lg-3'>
                            <Select name="orderType" onChange={ handleChangeOrderType } value={ orderType } styles={ selectStyles } placeholder={ strings.choisissez_type} isMulti isClearable={true} options={ orderTypes }  />
                        </div>
                        <div className='col-lg-3'>
                            <Select name="orderState" onChange={ handleChangeOrderStatus } value={ orderState } styles={ selectStyles } placeholder={ strings.choisissez_etat } isMulti isClearable={true} options={ orderStatus }  />
                        </div>
                        <div className='col-lg-2'>
                            <button type='submit' className='btn btn-primary w-100 text-white'>
                                { strings.rechercher}
                            </button>                     
                        </div>
                    </div>    
                </div>   
                
                <DataTable
                    columns={columns}
                    data={filteredOrders}
                    highlightOnHover
                    pointerOnHover
                    pagination
                    paginationComponentOptions={paginationComponentOptions}
                    onRowClicked={ (row, event) => { editOrder(row.ref) } }
                    paginationPerPage={ 5 }
                    striped={ true }
                    theme="bs5"
                    customStyles={ bs5Styles }
                    conditionalRowStyles={conditionalRowStyles}
                    noDataComponent={ <div className='alert alert-warning w-100 m-0'><i className="fa fa-exclamation-triangle me-3"></i>{ strings.orders_noresult }</div> }
                />
               
            </div>
        </div>        
        
    )
}

function OrderAdd(){
    const [title,setTitle] = useState(strings.order_add)
    return (
        <div className='order-new h-100 d-flex flex-column justify-content-between'>
            <Ariane>
                <h1 className='titre text-center flex-grow-1'>{ title }</h1>
            </Ariane>
            <OrderForm updateTitle={ setTitle } />
        </div>
    )
}

function OrderEdit(){   
    
    const [title,setTitle] = useState(strings.order_edit)
    return (
        <div className='order-new h-100 d-flex flex-column justify-content-between'>
            <Ariane>
                <h1 className='titre text-center flex-grow-1'>{ title }</h1>
            </Ariane>
            <OrderForm updateTitle={ setTitle }  />
        </div>
    )
}

export default Orders;
export { OrderAdd, OrderEdit };