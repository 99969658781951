import { useApi } from './Api';
import React, { useState, useEffect, useContext } from 'react';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { useIndexedDB } from 'react-indexed-db';
import { ServiceWorkerUpdateListener } from '../ServiceWorkerUpdateListener.js'
import { strings } from '../localization';
import ProgressBar from 'react-bootstrap/ProgressBar';



export default function Synchronizer(){  
    
    const [ user, { fetchAPI, setUser } ] = useApi();
    const [ upgrading, setUpgrading ] = useState(false)
    const { update, getAll } = useIndexedDB('catalog');
    const [customers, setCustomers] = useState([]);
    const [orders, setOrders] = useState([]);
    const [products, setProducts] = useState([]);
    const [categories, setCategories] = useState([]);
    const [updateWaiting, setUpdateWaiting] = useState(false);
    const [updateCheck, setUpdateCheck] = useState(false);
    const [registration, setRegistration] = useState(null);
    const [swListener, setSwListener] = useState({});
    const [downloadTask, setDownloadTask] = useState([]);
    
    
    useEffect(() => {
        
        getAll().then(dataFromDB => {
            setProducts(dataFromDB.find(i => i.type == 'products')?.data ?? [])
            setCategories(dataFromDB.find(i => i.type == 'categories')?.data ?? [])
            setCustomers(dataFromDB.find(i => i.type == 'customers')?.data ?? [])
            setOrders(dataFromDB.find(i => i.type == 'orders')?.data ?? [])
        })

        updateStorageInfo()
    

        if (process.env.NODE_ENV !== "development") {

            try{

                let listener = new ServiceWorkerUpdateListener();

                setSwListener(listener);

                listener.onupdateinstalling = (installingEvent) => {
                    setUpdateCheck(true)           
                };

                listener.onupdatewaiting = (waitingEvent) => {
                    setUpdateCheck(false)
                    setUpdateWaiting(true);
                    
                };

                listener.onupdateready = (event) => {
                    window.location.reload();
                };

            
                let intervalCheck;
            
                navigator.serviceWorker.getRegistration().then((reg) => {

                    
                
                    listener.addRegistration(reg);
                    setRegistration(reg);
                    //console.log("Check for updates")
                    window.setTimeout(function(){ reg.update(); }, 3000)
                    intervalCheck = window.setInterval(() => { /*console.log('Check for updates');*/ reg.update(); },30000)
                    
                });
        
                return () => { listener.removeRegistration(); clearInterval(intervalCheck) }
            
            }catch(error){
                console.log(error)
            }
        } else {
          //do nothing because no sw in development !
        }
    }, [])

    let downloadable = { 
        'category' : {
            'download_text' : strings.category_download,
            'download_text_completed' : strings.category_download_completed,
            'delete_text' : strings.category_delete,
            'delete_text_plural' : strings.category_delete_plural,
            'index' : 0,
            'process' : 0,
            'completed' : 0,
            'failed' : 0,
            'max_process' : 5
        },
        'product' : {
            'download_text' :  strings.product_download,
            'download_text_completed' :  strings.product_download_completed,
            'delete_text' :  strings.product_delete,
            'delete_text_plural' :  strings.product_delete_plural,
            'index' : 0,
            'process' : 0,
            'completed' : 0,
            'failed' : 0,
            'max_process' : 5
        },
    }
      

    const [ modalData, setModalData ] = useState({title:'', body:{}});

   

    const [report,setReport] = useState([])    

    const logReport = (message, type) => {        
        setReport(prevReport => [...prevReport, <li key={ 'report_'+prevReport.length+1 } className={ 'text-'+type }>{ message }</li>])
    }

    let addToCache = async (cache, url,type,callback) => {

       return fetch(url).then( response => {   
            
            if (response.ok) {                

                cache.put(url, response);
                downloadable[type].completed++;

               
            }
            else
            {
                downloadable[type].failed++;
                let errorCount = Object.values(downloadable).reduce((previous,{failed}) => failed+previous,0)
                addMessage('sync_image_failed',
                    <div className='text-danger'>                
                        <i className='fa fa-exclamation-triangle me-3'></i>
                        { strings.failed } : { errorCount }
                    </div>
                )
                logReport(response.status+":"+url.replace('https://api.faye-sas.fr/',''),'danger')
             
            }

            return response

        }).catch( (error) => {
            downloadable[type].failed++;
            let errorCount = Object.values(downloadable).reduce((previous,{failed}) => failed+previous,0)
            addMessage('sync_image_failed',
                <div className='text-danger'>                
                    <i className='fa fa-exclamation-triangle me-3'></i>
                    { strings.failed } : { errorCount }
                </div>
            );  
            logReport(error.message,'danger')
            //console.log(error)
        }).finally(() => {               
            downloadable[type].process--;  
            updateStorageInfo();
            callback();
        })
    }


    let downloadImage = (cache, images,type,cb) => {       

        //console.log(downloadable[type].process);
        

        while(downloadable[type].process <= downloadable[type].max_process && downloadable[type].index < images.length)
        {
            const url = images[downloadable[type].index];
            downloadable[type].process++; 
            downloadable[type].index++;

            addMessage('sync_image_'+type,
                <>                
                    <div className="spinner-border spinner-border-sm me-3" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                    { downloadable[type].download_text } : { downloadable[type].completed+" / "+images.length }
                </>
            );  

            const nextImage = () => {
                downloadImage(cache, images,type,cb); 
                if(downloadable[type].index >=  images.length){                        
                    cb();
                }  
            }

            // caches.match(url).then((response) => {

            //     if(response !== undefined)
            //     {
            //         downloadable[type].process--; 
            //         nextImage()  
            //     } 
            //     else
            //     addToCache(cacheName, url,type, nextImage)   

            // })
            
            addToCache(cache, url,type, nextImage)   
            
            
        }

        
    

    }

    let startDownload = (cacheName,images,type,callback) => {

        //caches.delete(cacheName);

        caches.open(cacheName).then(cache => {
            cache.keys().then((keys) => {  
                let cacheURL =  keys.map(request => request.url)
                let newImages = images.filter(url => ! cacheURL.includes(url))            
                

                /*console.log("Images "+type, images)
                console.log("Delete images "+type,toDelete)*/
                //console.log("New images "+type,newImages)              

                
                
                if(newImages.length)
                downloadImage(cache,newImages,type, callback)
                else
                addMessage('sync_image_complete'+type,
                    <>                
                        <i className='fa fa-check text-success me-3'></i>
                        { downloadable[type].download_text_completed }
                    </>
                );  
            });
        })       

        
    }

    const [deviceEstimate, setDeviceEstimate] = useState()    

    const updateStorageInfo = () => {
        if ('storage' in navigator && 'estimate' in navigator.storage) { 
            navigator.storage.estimate() 
            .then(function(estimate){ 
                setDeviceEstimate(estimate)
            }); 
        }
    }

    const  addMessage  = (key,message, update = true)  => {       
        
        //console.log(update, lastModalData?.body)
        
        // if( ! update && lastModalData?.body[key])
        // delete lastModalData?.body[key]

        setModalData( previousData => ({ body: {...previousData.body, [key] : message } }) )

    }    

    const handleProductSync = () => {   

        setUpgrading(true)       
        
        
        if( ! downloadTask.includes('categories') ){
            
            setDownloadTask([ ...downloadTask, 'categories'])

            addMessage('sync_category', <>                
                <div className="spinner-border spinner-border-sm me-3" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                { strings.category_update }
            </>, false)
            

            fetchAPI('/categories').then( response => {                
            
                const remoteCategories = response.data

                return update({type: 'categories', data: remoteCategories}).then(event => {
                    
                    addMessage('sync_category',
                        <>                
                            <i className='fa fa-check text-success me-3'></i>
                            { strings.category_update } : { strings.completed }
                        </>
                    );

                    setCategories(remoteCategories)
                    setDownloadTask([...downloadTask].splice(downloadTask.indexOf('categories'),1))
                    return remoteCategories
                });
                
                

                
                
            })

        }
        if( ! downloadTask.includes('products') ){
            
            setDownloadTask([ ...downloadTask, 'products'])
            

            addMessage('sync_product', <>                
                <div className="spinner-border spinner-border-sm me-3" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                { strings.product_update }
            </>, false)

        
            fetchAPI('/products').then( response => {
                    
                
                const remoteProducts = response.data

                return update({type: 'products', data: remoteProducts}).then(event => {

                    addMessage('sync_product',
                        <>                
                            <i className='fa fa-check text-success me-3'></i>
                            { strings.product_update} : { strings.completed }
                        </>
                    );  

                    setProducts(remoteProducts)
                    setDownloadTask([...downloadTask].splice(downloadTask.indexOf('products'),1))
                    return remoteProducts
                    
                });
            
                
            

            })

        }
    }

    const recursiveGetCategoryImage = (cats) => {


        let images = [] 

        cats.forEach(cat => {

            if(cat.image)
            images.push(cat.image);

            if(cat.children)
            images = [ ...images, ...recursiveGetCategoryImage(cat.children)];
        });

        return images
    }

    const handleImageSync = () => {  

        setUpgrading(true)   

        
        
        if( ! downloadTask.includes('category_images') ){
        
            setDownloadTask([ ...downloadTask, 'category_images'])
            // const categories = JSON.parse(localStorage.getItem('categories'))
            // const products = JSON.parse(localStorage.getItem('products'))
        
            
            let images = recursiveGetCategoryImage(categories);               

            
            startDownload("image-cache",images,"category",() => {

                addMessage('sync_image_category',
                    <>                
                        <i className='fa fa-check text-success me-3'></i>
                        { downloadable.category.download_text } : { strings.completed }                        
                    </>
                );
                setDownloadTask([...downloadTask].splice(downloadTask.indexOf('category_images'),1))

            });
        }
        
        if( ! downloadTask.includes('product_images') ){
            
            setDownloadTask([ ...downloadTask, 'product_images'])

            let images = [];

            products.forEach(product => {
                images = [ ...images, ...product.images ];                
            });      


            startDownload("image-cache",images,"product",() => {
                
                addMessage('sync_image_product',
                    <>                
                        <i className='fa fa-check text-success me-3'></i>
                        { downloadable.product.download_text } : { strings.completed }
                    </>
                );

                setDownloadTask([...downloadTask].splice(downloadTask.indexOf('product_images'),1))
                
            });    

        }

        if( ! downloadTask.includes('clean_cache') ){

            setDownloadTask([ ...downloadTask, 'clean_cache'])

            let images = recursiveGetCategoryImage(categories);           

            products.forEach(product => {
                images = [ ...images, ...product.images];                
            });  

            

            caches.open('image-cache').then(cache => {
                cache.keys().then((keys) => {  
                    let toDelete = keys.filter(request => !images.includes(request.url));

                    toDelete.forEach( request => {                    
                        cache.delete(request);                        
                    });

                    if(toDelete.length)
                    addMessage('sync_image_delete',
                        <>                
                            <i className='fa fa-check text-success me-3'></i>
                            { toDelete.length } { toDelete.length > 1 ? strings.image_delete_plural : strings.image_delete }
                        </>
                    );  

                    setDownloadTask([...downloadTask].splice(downloadTask.indexOf('clean_cache'),1))
                })
            })
        }
        
         
    }

    const putCustomers = (new_customers) => {

        //console.log('put customers',new_customers);    
      
        if(new_customers.length > 0)
        {
            return fetchAPI('/customers',{},new_customers,'put').then(response => {
            
                const inserted_customers = response.data.inserted_rows; 
                const updated_customers = response.data.updated_rows; 
                //console.log('inserted customers', inserted_customers)

                let failedCustomers = []
                if(inserted_customers.length)
                {
                    
                    addMessage('sync_customers_add',
                        <>                
                            <i className='fa fa-check text-success me-3'></i>
                            { strings.customer_added} ({ inserted_customers.length }) : { strings.completed }
                        </>
                    ) 
                    failedCustomers  = new_customers.filter(c => 
                        ! inserted_customers.map(i => i.ref).includes(c.ref) 
                        && 
                        ! inserted_customers.map(i => i.auto_ref).includes(c.ref) 
                        && 
                        ! updated_customers.map(i => i.ref).includes(c.ref) 
                        && 
                        ! updated_customers.map(i => i.api_ref).includes(c.ref)
                    )
                }

                return failedCustomers
            })
        }
        else
        return new Promise( (resolve, reject) => resolve([]))

    }

    const getCustomers = () => {

        return fetchAPI('/customers').then(response => response.data)        

    }

    const putOrders = (new_orders) => {

        addMessage('sync_orders', <>                
            <div className="spinner-border spinner-border-sm me-3" role="status">
                <span className="sr-only">Loading...</span>
            </div>
            { strings.order_update }
        </>,false)

       
        if(new_orders.length > 0)
        {
            return fetchAPI('/orders',{},new_orders, 'put').then(response => {

                const inserted_orders = response.data.inserted_rows;
                const updated_orders = response.data.updated_rows;  
                //console.log('inserted orders', inserted_orders)  
                
                let failedOrders = []

                if(inserted_orders.length)
                {
                    
                    addMessage('sync_orders_add',
                        <>                
                            <i className='fa fa-check text-success me-3'></i>
                            { strings.order_added} ({ inserted_orders.length }) : { strings.completed }
                        </>
                    ,false) 

                    failedOrders  = new_orders.filter(o => 
                        ! inserted_orders.map(i => i.ref).includes(o.ref) 
                        && 
                        ! inserted_orders.map(i => i.auto_ref).includes(o.ref) 
                        && 
                        ! updated_orders.includes(o.ref))
                } 
                

                return failedOrders;

            })
            
        }
        else
        return new Promise( (resolve, reject) => resolve([]))

       
        

    }

    const getOrders = () => {      
        
        return fetchAPI('/orders').then(response => response.data)

    }

    const handleSageSync = () => {

        // AFFICHAGE DU MODAL
        setUpgrading(true)  
        

        if( ! downloadTask.includes('sage') ){
            
            setDownloadTask([ ...downloadTask, 'sage'])
        
            addMessage('sync_customers', <>                
                <div className="spinner-border spinner-border-sm me-3" role="status">
                    <span className="sr-only">Loading...</span>
                </div>
                { strings.customer_update }
            </>, false)

            // FILTRE DES PROSPECTS (CLIENTS NON SYNCHRONISES)
            let prospects = customers.filter(c => c?.prospect)

            // ENVOI DES NOUVEAUX CLIENTS A L'API
            putCustomers(prospects).then(localCustomers => {    

                // RECUPERATION DE LA BASE CLIENT
                return getCustomers().then( remoteCustomers => {

                    // FUSION DE LA BASE ET DES CLIENTS EN ECHEC DE SYNCHRO 
                    const customers_data = [ ...remoteCustomers, ...localCustomers ]                             

                    // MAJ DE LA BDD
                    return update({type: 'customers', data: customers_data}).then(event => {
                        
                        addMessage('sync_customers',
                            <>                
                                <i className='fa fa-check text-success me-3'></i>
                                { strings.customer_update} : { strings.completed }
                            </>
                        );                   

                        return customers_data
                    });

                })

            }).then(updatedCustomers => { 

                // MAJ DU USER / CUSTOMERS
                user.customer_num = updatedCustomers.length+1
                user.contact_num = updatedCustomers.map(c => c.contacts).flat().length+1
                user.address_num = updatedCustomers.map(c => c.addresses).flat().length+1
                setUser(user)
                setCustomers(updatedCustomers)            

                // FILTRE DES NOUVELLES COMMANDES
                let new_orders = orders.filter(o => o?.status === 1)  
                
                // ENVOI DES NOUVELLES COMMANDES VERS L'API
                return putOrders(new_orders)

            }).then(failedOrders => {

                let pendingOrders = orders.filter(o => ! o.status );
                
                // RECUPERATION DE LA BASE COMMANDE
                return getOrders().then( remoteOrders => {

                    // FUSION DE LA BASE ET DES COMMANDES EN ECHEC DE SYNCHRO OU EN ATTENTE DE VALIDATION
                    const orders_data = [ ...pendingOrders, ...failedOrders, ...remoteOrders ]
                    //console.log('updated orders', orders_data)

                    // MAJ DE LA BDD
                    return update({type: 'orders', data: orders_data}).then(event => {
                        addMessage('sync_orders',
                            <>                
                                <i className='fa fa-check text-success me-3'></i>
                                { strings.order_update} : { strings.completed}
                            </>
                        );

                        return orders_data
                    })

                })        
            }).then(updatedOrders => {

                // MAJ DU USER / ORDERS
                user.order_num = updatedOrders.length+1
                setUser(user)
                setOrders(updatedOrders)
                setDownloadTask([...downloadTask].splice(downloadTask.indexOf('sage'),1))
            })
        
        }
       

    }

   

    const handleClearCache = () => {
        swListener.skipWaiting(registration.waiting);
    }
    

    const handleClose = () => {
        setUpgrading(false)
    }

    let freeSpace = deviceEstimate ? Math.round((deviceEstimate.quota-deviceEstimate.usage)/1000000*100)/100 : 0
    freeSpace = freeSpace > 1000 ? new Intl.NumberFormat('fr-FR',).format( Math.round(freeSpace/1000*100)/100 )+" Gb" : new Intl.NumberFormat('fr-FR',).format( freeSpace )+" Mb"
  
  

    return (
        <div className='page-synchronizer container-xxl'> 
            <h1 className='mt-5 mb-5 text-center'>{ strings.synchro_title}</h1>            
            <div className="row flex-wrap justify-content-center mb-3">
                <div className='col-md-6 col-xl-4'>
                    <button type="button" className="btn btn-primary btn-lg text-start text-white w-100" onClick={ handleProductSync }>
                        <i className='fa fa-box me-3'></i>
                        { strings.synchro_produits }
                    </button>
                </div>
            </div>
            <div className="row flex-wrap justify-content-center mb-3">
                <div className='col-md-6 col-xl-4'>
                    <button type="button" className="btn btn-primary btn-lg text-start text-white w-100" onClick={ handleImageSync }>
                        <i className='fa fa-image me-3'></i>
                        { strings.synchro_images }
                    </button>
                </div>
            </div>
            <div className="row flex-wrap justify-content-center mb-3">
                <div className='col-md-6 col-xl-4'>
                    <button type="button" className="btn btn-primary btn-lg text-start text-white w-100" onClick={ handleSageSync }>
                        <i className='fa fa-users me-3'></i>
                        { strings.synchro_commandes }
                    </button>
                </div>
            </div>
            <div className="row flex-wrap justify-content-center mb-3">
                <div className='col-md-6 col-xl-4'>
                    <button type="button" disabled={ ! updateWaiting } className={ "btn btn-lg text-start text-white w-100 " + ( updateWaiting ? 'btn-success' : 'btn-primary')} onClick={ handleClearCache }>
                        
                        { updateCheck ? 
                            <>
                                <div className="spinner-border spinner-border-sm me-3" role="status">
                                    <span className="sr-only">Loading...</span>
                                </div>
                                { strings.update_check }
                            </>
                            :
                            <>
                                <i className='fa fa-cogs me-3'></i>
                                { updateWaiting ?  strings.appli_maj : strings.appli_ajour+" | Version "+process.env.REACT_APP_VERSION }
                            </>
                        }
                        
                    </button>
                </div>
            </div>
            <div className='row flex-wrap justify-content-center mb-3'>
                <div className='col-md-6 col-xl-4'>
                    <ProgressBar>
                        <ProgressBar key={1} variant="danger" striped={true} animated={ downloadTask.length > 0 }  now={ deviceEstimate ? (deviceEstimate.usage/deviceEstimate.quota) * 100 : 10 }  label={ (deviceEstimate ? Math.round(deviceEstimate.usage/deviceEstimate.quota) *100 : '')+"%"}/>
                        <ProgressBar key={2}  variant="success"   now={ 100 } />
                    </ProgressBar>
                    
                    { deviceEstimate ? new Intl.NumberFormat('fr-FR',).format( Math.round(deviceEstimate.usage/1000000*100)/100 )+" Mb utilisés|" : '' }
                    { deviceEstimate ? freeSpace+" libres" : 'Estimation non disponible' }
                    {/* { report.length > 0 && 
                    <div className='border bg-white text-start p-3 small overflow-scroll mt-3'>
                       <ul className='p-0 m-0'>{ report.map(r => r)}</ul>
                    </div>} */}
                    
                </div>
            </div>
            
                     
            <Modal show={upgrading} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                <Modal.Title>{ modalData.title }</Modal.Title>
                </Modal.Header>        
                <Modal.Body>
                    <ul>
                        { Object.keys(modalData.body).map( (key,index) =>     
                            <li key={index}>{ modalData.body[key] }</li>
                        ) }
                    </ul>
                </Modal.Body>        
                <Modal.Footer>        
                    <Button onClick={ handleClose } variant="secondary">{ strings.close }</Button>
                </Modal.Footer>
            </Modal>
        </div>
    )

}