import banner from '../assets/images/banner.jpg';
import React, { useState, useEffect, useRef } from 'react';
import CustomerForm from '../forms/CustomerForm';
import { Link, useNavigate, useParams} from 'react-router-dom';
import Ariane from './Ariane';
import DataTable, { createTheme } from 'react-data-table-component';
import bs5Styles from '../assets/themes/bs5';
//const regionNamesInLocaleLanguage = new Intl.DisplayNames('fr', { type: 'region' });
import { useIndexedDB } from 'react-indexed-db';
import { strings } from '../localization';
import { confirmAlert } from 'react-confirm-alert'; // Import

function Customers(){

    //const customers = JSON.parse(localStorage.getItem('customers'))
    const { getAll, update } = useIndexedDB('catalog');
    const [customers, setCustomers] = useState([]);
   
    useEffect(() => {
        getAll().then(dataFromDB => {
            setCustomers(dataFromDB.find(i => i.type == 'customers')?.data ?? [])
        });

        setCustomerSearch(sessionStorage.getItem('customers.search_ref') ?? '');
    }, []);


    let navigate = useNavigate();
    const addNewCustomer = () => {  
        navigate('/customers/new');
    }

    const editCustomer = (ref) => {
        navigate('/customer/'+ref)
    }

    const handleDeleteCustomer = (ref) => {

        confirmAlert({
            title: 'Supprimer un client',
            message: 'Êtes-vous sûr de vouloir supprimer ce client ?',
            buttons: [
              {
                label: 'Oui',
                onClick: () => {
                    let newCustomers = customers.filter(c => c.ref != ref)
       
                    update({type: 'customers', data: newCustomers}).then(event => {

                        setCustomers(newCustomers)

                    })
                }
              },
              {
                label: 'Annuler',
                onClick: () => {
                    
                }
              }
            ]
        });

        
    }

    const columns = [
        {
            name: <i className="fa fa-circle"></i>,
            selector: row => row.monitoring,
            width: '60px',
            sortable: true,
            format: row => <i className={ 'fa fa-circle text-' + (row.monitoring === null ? 'warning' : ( row.monitoring ? 'success' : 'danger' )) }></i>
        },
        {
            name: strings.ref,
            selector: row => row.ref,
            sortable: true,
        },
        {
            name: strings.raison,
            selector: row => row.company,
            sortable: true,
        },
        {
            name: strings.tel,
            selector: row => row.billing_phone,
            sortable: true,
            right: true,
        },
        {
            name: strings.contact,
            selector: row => row.contact,
            right: true,
            sortable: true,
            format: row => row.contacts.length ? row.contacts[0].first_name+" "+row.contacts[0].last_name : ''
        },
        {
            name: strings.ville,
            selector: row => row.billing_city,
            right: true,
            sortable: true,
        },
        {
            name: strings.pays,
            selector: row => row.billing_country,
            right: true,
            sortable: true,
            format: row => { try{ return /*regionNamesInLocaleLanguage.of(row.billing_country).toUpperCase();*/ row.billing_country }catch{ return row.billing_country } }
        },
        {
            name: strings.actions,
            selector: row => row.prospect,
            center: true,
            sortable: false,
            width:'100px',
            format: row => row.prospect ? <button type="button" onClick={ () => handleDeleteCustomer(row.ref) } className='btn text-secondary btn-sm'><i className='fa fa-trash'></i></button> : ''
        },
        
    ];
    
    const paginationComponentOptions = {
        rowsPerPageText: strings.articles_page,
        rangeSeparatorText: strings.articles_of,
        selectAllRowsItem: true,
        selectAllRowsItemText: strings.articles_all,
        
    };

    const [ customerSearch, setCustomerSearch ] = useState('')

    const handleCustomerSearch = (e) => {

        console.log(e.target.value)
        sessionStorage.setItem('customers.search_ref', e.target.value);
        setCustomerSearch(e.target.value)

    }

    const filteredCustomers = customers.filter( (customer) => {
        return customer.ref.toLowerCase().includes(customerSearch.toLowerCase()) 
        || 
        customer.company.toLowerCase().includes(customerSearch.toLowerCase())
    })

    return (
        
        <div className="customers">
            <Ariane actions={<button type="button" className='me-3 btn btn-secondary' onClick={ addNewCustomer }><i className="fa fa-plus"></i> { strings.ajouter_client }</button>}>
                <h1 className='titre flex-grow-1 text-center'>{ strings.liste_clients }</h1>
            </Ariane>
            
            <div className='customers-list container-xxl'>
                <div className="search-bar my-3 py-3 row justify-content-end">
                    <div className='col-lg-3'>
                        <input type="text" name="q" onChange={ handleCustomerSearch } value={ customerSearch } className="form-control" placeholder={ strings.rechercher_client } />                        
                    </div>
                    
                    <div className='col-lg-3'>
                        <button type='submit' className='btn btn-primary w-100 text-white'>
                            { strings.rechercher }
                        </button>                     
                    </div>
                </div>  
                <DataTable
                    columns={columns}
                    data={filteredCustomers}
                    highlightOnHover
                    pointerOnHover
                    pagination
                    paginationComponentOptions={paginationComponentOptions}            
                    paginationPerPage={ 5 }
                    striped={ true }
                    theme="bs5"
                    customStyles={ bs5Styles }
                    onRowClicked={ (row, event) => { editCustomer(row.ref) } }
                    noDataComponent={ <div className='alert alert-warning w-100 m-0'><i className="fa fa-exclamation-triangle me-3"></i>{ strings.customer_noresult }</div> }
                />  
            </div>
        </div>        
        
    )
}

function CustomerAdd(){

    const handleNewCustomer = values => {
       
        window.alert(JSON.stringify(values, 0, 2))
    }
    const [title,setTitle] = useState(strings.customer_add)
    
    return (
        <div className='customer-new h-100 d-flex flex-column justify-content-between'>
            <Ariane>
                <h1 className='titre flex-grow-1 text-center'>{ title }</h1>
            </Ariane>
            <CustomerForm updateTitle={ setTitle } handleNew={ handleNewCustomer } />
        </div>
    )
}

function CustomerEdit(){    
   
    

     const [title,setTitle] = useState(strings.customer_details)
    
    return (
        <div className='customer-new h-100 d-flex flex-column justify-content-between'>
            <Ariane>
                <h1 className='titre flex-grow-1 text-center'>{ title }</h1>
            </Ariane>
            <CustomerForm updateTitle={ setTitle }  />
        </div>
    )
}

export default Customers;
export { CustomerAdd, CustomerEdit };