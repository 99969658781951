import { createTheme } from 'react-data-table-component';
import theme from '../scss/theme.scss';

createTheme(
    'bs5',
     {
         text: {
             primary: theme.secondary_darker,
             secondary: theme.secondary,                
         },
         background: {
         default: '#FFF',
         },
         context: {
             background: '#cb4b16',
             text: '#FFFFFF',
         },
         divider: {
             default: theme.secondary,
         },
         button: {
             default: theme.secondary,
             hover: 'rgba(0,0,0,.08)',
             focus: 'rgba(255,255,255,.12)',
             disabled: 'rgba(255, 255, 255, .34)',
         },
         selected: {
             default: '#e3f2fd',
             text: 'rgba(0, 0, 0, 0.87)',
         },
         highlightOnHover: {
             default: theme.primary,
             text: 'white',
         },
         striped: {
             default: 'rgba(0,0,0,.02)',
             text: theme.white,
         },

     }
);

export default {
    headRow: {
        style: {
            border: '1px solid white',
        }
    },
    headCells: {
        style: {
            color: theme.secondary_darker,
            fontSize: '12px',
            fontWeight: '700',
            border: '1px solid white',
            backgroundColor: theme.primary_lighter
        },       
    },
    rows: {
        style:{
            '&:not(:last-of-type)': {
                borderBottomStyle: 'solid',
                borderBottomWidth: '0px',
                borderBottomColor: 'red',
            },
        },
        highlightOnHoverStyle: {
            
            borderBottomColor: '#FFFFFF',
            outline: '1px solid #FFFFFF',
        },
    },
    cells: {
        style: {
            borderLeft: '1px solid white',
            borderRight: '1px solid white',
            paddingLeft: '16px',
            paddingRight: '16px',
            wordBreak: 'break-word',
        },
        draggingStyle: {},
    },
    pagination: {
        style: {
            border: 'none',
        },
    },
};

