import React, { useState, useEffect, useContext } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useLocation, useNavigate, Link } from "react-router-dom";
import logoEfya from './assets/images/logo-efya-white.png';
import logoFaye from './assets/images/logo-faye.png';

import './assets/scss/bootstrap.scss';
import './assets/css/fonts.css';
import './assets/fonts/fontawesome/css/all.min.css';
import 'react-confirm-alert/src/react-confirm-alert.css';
import './assets/scss/App.scss';



import { useApi } from './components/Api';
import Auth from './components/Auth';
import {UserContext} from './contexts/UserContext';
import { CartProvider } from "react-use-cart";
import Dashboard from './components/Dashboard.js';
import Customers, { CustomerAdd, CustomerEdit } from './components/Customers.js';
import Orders, { OrderAdd, OrderEdit } from './components/Orders.js';
import Catalog, { ProductList, ProductSingle } from './components/Products.js';
import LogoutBtn from './components/LogoutBtn';
import Cart from './components/Cart';
import Synchronizer from './components/Synchronizer';
import { DBConfig } from './components/dbConfig';
import { initDB } from 'react-indexed-db';
import { strings } from './localization';
import { Dropdown } from 'react-bootstrap';
import Flag from 'react-world-flags'



initDB(DBConfig)

Object.defineProperty(String.prototype, 'capitalize', {
  value: function() {
    return this.charAt(0).toUpperCase() + this.slice(1);
  },
  enumerable: false
});


function App(){ 
  
  const [user, { setUser, setNewUser, login, logout, errors } ] = useApi();

  
  const [settings, _setSettings] = useState({})

  const setSettings = (newSettings) => {

    let updatedSettings = { ...settings, ...newSettings}
    localStorage.setItem('settings',JSON.stringify(updatedSettings))
    _setSettings(updatedSettings)

  }
  const setLanguage = (code, event) => {

      setSettings({ lang: code })
      strings.setLanguage(code);
  }




  useEffect(() => {
    
    const loggedInUser = localStorage.getItem("user");
    
    if (loggedInUser) {   
     
        
      const foundUser = JSON.parse(loggedInUser);  
      setUser(foundUser);      
              
     
    }
    else
    setNewUser()

    

    setLanguage(localStorage.getItem('settings') ? JSON.parse(localStorage.getItem('settings')).lang :  'fr')
   
    
  }, []);

 


  return(       
    <div className="App">
      <UserContext.Provider value={ { user, setUser,settings, setLanguage } }>
        <BrowserRouter>
          <Routes>   
            <Route path="/" element={  
                          
              user != null ? (
                ! user?.isLoggedIn 
                ?
                <Navigate to="/login" replace={true} />
                :
                <Navigate to="/dashboard" replace={true} />                
              ):(
                <div></div>
              )
            } />       
            <Route exact path="/login" element={ 
              <Page className="auth-page" protected={ false } header={ true } onLogout={ logout }>
                <Auth errors={ errors } onLogin={ login } onLogout={ logout } />
              </Page>
            } />
            <Route exact path="/dashboard" element={
              <Page className="dashboard-page" header={true} onLogout={ logout } >
                <Dashboard />
              </Page>
            } />
            <Route exact path="/customers" element={
              <Page className="customers-page">
                <Customers />
              </Page>
            } />
            <Route exact path="/customers/new" element={
              <Page className="customer-new-page">
                <CustomerAdd />
              </Page>
            } />
            <Route exact path="/customer/:customerRef" element={
              <Page className="customer-edit-page">
                <CustomerEdit />
              </Page>
            } />  
            <Route exact path="/products" element={
              <Page className="products-page">
                <CartProvider>
                  <ProductList />
                </CartProvider>
              </Page>
            } />        
            
            <Route path="/product/:productRef" element={
              <Page className="product-single-page">
                <CartProvider>
                  <ProductSingle />
                </CartProvider>
              </Page>
            } />
            <Route path="/catalog/*" element={
              <Page className="products-page">
                <CartProvider>
                  <Catalog />
                </CartProvider>
              </Page>
            } />
            <Route exact path="/cart" element={
              <Page className="cart-page">
                <CartProvider>
                  <Cart />
                </CartProvider>
              </Page>
            } />
            <Route exact path="/orders" element={
              <Page className="orders-page">
                <Orders />
              </Page>
            } />
            <Route exact path="/orders/new" element={
              <Page className="order-new-page">
                <CartProvider>
                  <OrderAdd />
                </CartProvider>
              </Page>
            } />
            <Route exact path="/order/:orderRef" element={
              <Page className="order-edit-page">
                <CartProvider>
                    <OrderEdit />
                </CartProvider>
              </Page>
            } />   
            <Route exact path="/sync" element={
              <Page className="sync-page" header={ true } onLogout={ logout }>
                <Synchronizer />
              </Page>
            } />
          </Routes>
        </BrowserRouter>
      </UserContext.Provider>
    </div>           
  )
  

}

function Header(props){

    

   
  
  return (
    <UserContext.Consumer>
      { ( {user, setUser, settings, setLanguage} ) => {
        return (
          <header className={ "App-header d-flex" + ( user?.isLoggedIn ? " logged" : "" ) }>
            <div className="container-xxl">
              <div className="row align-items-end justify-content-center h-100">
                <div className="col-6 col-lg-2 p-3 text-end p-4">
                  <Link to="/">
                    <img src={logoFaye} className="logo-faye img-fluid" alt="logo Faye" />
                  </Link>
                </div>
                <div className="col-6 col-lg-2 p-3 text-start p-4">
                  <img src={logoEfya} className="logo-efya img-fluid" alt="logo Efya" />
                </div>
                { user?.isLoggedIn && 
                  <div className="user-info col-12 col-lg-8 flex-grow-1 justify-content-end align-items-center text-white d-flex p-4 fs-3">
                      <strong>
                      <i className="far fa-user-circle me-2"></i> { strings.bonjour } { user.display_name?.capitalize() }
                      </strong>
                      <Dropdown onSelect={ setLanguage }>
                        <Dropdown.Toggle  id="dropdown-lang">
                            <Flag width="30" code={ settings.lang ?? 'fr' } />
                        </Dropdown.Toggle>
                        <Dropdown.Menu className='dropdown-menu-lang'>
                            <Dropdown.Item eventKey="fr">                                
                                <Flag width="30" code="fr" />
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="es">
                                <Flag width="30" code="es" />
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="it">
                                <Flag width="30" code="it" />
                            </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                      <LogoutBtn onLogout={ props.onLogout } />
                  </div>
                }
              </div>
            </div>        
          </header>
        )
      }}
    </UserContext.Consumer>
  )
}

function Body(props){

  return (
    <div className="App-container d-flex">
      <div className='col-12'>
        { props.children }
      </div>
    </div>
  )
}

function Page(props){

  let pageContent = <div className={ "page "+ props.className }>
  { props.header && 
    <Header onLogout={ props.onLogout } />
  }
  <Body>
    { props.children }
  </Body>
  </div>;

  return (
    props.protected
    ?
    <ProtectedRoute>
      { pageContent }
    </ProtectedRoute>
    :
    pageContent
  )
}

Page.defaultProps = {
  header: false,
  protected: true,
}

const ProtectedRoute = (props) => {
  
  const user = JSON.parse(localStorage.getItem("user"));

  return(    
      ! user?.isLoggedIn 
      ?
      <Navigate to="/login" replace={true} />
      :
      props.children 
  ) 
};

export default App;
