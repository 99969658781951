export const DBConfig = {
    name: 'faye',
    version: 1,
    objectStoresMeta: [
        {
            store: 'catalog',
            storeConfig: { keyPath: 'type', autoIncrement: true },
            storeSchema: [
            ]
        }
    ]
}