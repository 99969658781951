import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { strings } from '../localization';

function Ariane(props){

    const navigate = useNavigate();

    const handleBackBtnClick = (e) => {
        e.preventDefault();
        props.back ? navigate(props.back) : navigate(-1);

    }

    return (
        <div className='ariane mb-4 d-flex justify-content-between bg-primary p-3'>
            <button onClick={ handleBackBtnClick } className='btn border-white border-1 text-white align-items-center d-flex'>
                <i className='fa fa-chevron-left me-3'></i> { strings.retour }
            </button>
            { props.children }            
            <div className='d-flex align-items-center'>
                { props.actions }
                { props.showSearch && 
                    <div className='input-group me-3 d-none'>
                        <span className='input-group-text'>
                            <i className='fa fa-search'></i>
                        </span>
                        <input type="text" className='form-control' placeholder={ strings.rechercher +'..'} />
                    </div>
                }
                <Link to="/" className='text-white'>
                    <i className='fa fa-home fs-2'></i>
                </Link>
            </div>
        </div>
    )
}

Ariane.defaultProps = {
    showSearch: false,
    back: null,
    actions: null
}

export default Ariane;