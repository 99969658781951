import React from 'react';

const UserContext = React.createContext({
    user: {firstName: '', lastName: '', email: '', phone: '', isLoggedIn: false, token: null},
    setUser: () => {},
    settings: {},
    setLanguage: () => {},
});


export { UserContext };